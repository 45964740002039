import _debounce from 'lodash.debounce'

const CENTER_CLASS = 'product-comparison__center'

class ProductComparisonTable extends HTMLElement {
  constructor () {
    super()

    this.tableEl = this.querySelector('.js-comparison-table')
    this.scrollEl = this.querySelector('.js-comparison-scroll')
    this.contentEl = this.querySelector('.js-comparison-content')
    this.headerEl = this.querySelector('.js-comparison-header')
    const cellTopEls = this.querySelectorAll('.js-pc-cell-top')

    if (cellTopEls && cellTopEls.length) {
      setTimeout(() => {
        this.setHeightCellTopEls(cellTopEls)
        this.setModuleCenter()
      }, 1000)
      window.addEventListener('resize', _debounce(() => {
        this.setHeightCellTopEls(cellTopEls)
        this.setModuleCenter()
      }, 500))
    }
    let lastPos = 0
    this.scrollEl.addEventListener('scroll', () => {
      let currPos = this.scrollEl.scrollLeft
      if (lastPos < currPos) this.scrollRight()
      if (lastPos > currPos) this.scrollLeft()
      lastPos = currPos
    }, { passive: true })

    // Hide all rows and record which ones are active
    let activeComparisonRows = []
    this.querySelectorAll('.compare-row').forEach( (e,i) => {
      const cellValue = e.querySelector('.product-comparison__value').innerHTML.trim();
      this.querySelectorAll('[data-row]').forEach( (e,i) => {
        e.classList.add('hidden')
      })
      if(cellValue !== '' && activeComparisonRows.indexOf(e.dataset.row) === -1) {
        activeComparisonRows.push(e.dataset.row);
      }
    })

    // Show only the active rows
    activeComparisonRows.forEach((activeRow,i) => {
      this.querySelectorAll(`[data-row="${activeRow}"]`).forEach( (e,i) => {
        e.classList.remove('hidden')
      })
    })
  }

  setModuleCenter () {
    this.tableEl.classList.remove(CENTER_CLASS)
    const scrollWidth = this.scrollEl.clientWidth
    const contentWidth = this.contentEl.scrollWidth
    if (contentWidth > scrollWidth) {
      this.tableEl.classList.remove(CENTER_CLASS)
      this.tableEl.classList.add('scrolling')
      this.headerEl.classList.add('scrolling')
    } else {
      this.tableEl.classList.add(CENTER_CLASS)
      this.tableEl.classList.remove('scrolling')
      this.headerEl.classList.remove('scrolling')
    }
  }

  setHeightCellTopEls (cellTopEls) {
    cellTopEls.forEach(el => el.style.height = '')
    const maxHeight = Array.from(cellTopEls).reduce((height, el) => {
      return el.offsetHeight > height ? el.offsetHeight : height
    }, 0)
    if (maxHeight) {
      cellTopEls.forEach(el => el.style.height = maxHeight + 'px')
    }
  }

  scrollRight () {
    this.tableEl.classList.add('scrolling-right')
    this.tableEl.classList.remove('scrolling-left')
  }

  scrollLeft () {
    this.tableEl.classList.add('scrolling-left')
    this.tableEl.classList.remove('scrolling-right')
  }
}

customElements.define('product-comparison', ProductComparisonTable)